<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-6" v-if="!readOnly">
        <span class="text-gray-800 fs-6"
          >Genera attestato di frequentazione a:</span
        >
        <div class="btn-group d-flex flex-column">
          <button
            type="button"
            class="badge bg-secondary rounded blueFit fs-5 mb-2"
            style="max-width: 250px"
            @click="generaAttestatoPartecipazioneTutti"
          >
            Tutti i partecipanti</button
          ><button
            type="button"
            class="badge bg-secondary rounded blueFit fs-5"
            style="max-width: 250px"
            @click="generaAttestatoPartecipazioneSelezionati"
          >
            Solo i partecipanti selezionati
          </button>
        </div>
      </div>

      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          partecipanti di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        corsi_attestati_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="corsi_attestati_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsCorsi"
        @current-change="setCurrentPageCorsi"
        @checkboxClicked="
          checkAll = !checkAll;
          corsi_attestati_list.forEach((element) => {
            if (element.id_stato != 2) element.selected = checkAll;
          });
        "
        :checkedAll="checkAll"
      >
        <template v-slot:cell-checkbox="{ row: data }">
          <input
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id_societa"
          />
        </template>
        <template v-slot:cell-stagione="{ row: data }">{{
          data.stagione
        }}</template>
        <template v-slot:cell-nominativo_completo="{ row: data }">{{
          data.nominativo_completo
        }}</template>
        <template v-slot:cell-attestato_generato="{ row: data }">
          <span
            class="text-success"
            v-if="data.attestato_partecipazione_corso_generato"
            >SI</span
          >
          <span class="text-danger" v-else>NO</span>
        </template>
        <template v-slot:cell-scaricato="{ row: data }">
          <span class="text-success" v-if="data.attestato_qualifica_scaricato"
            >SI</span
          >
          <span class="text-danger" v-else>NO</span>
        </template>
        <template v-slot:cell-idoneo="{ row: data }">
          <span class="text-success" v-if="data.idoneo">SI</span>
          <span class="text-danger" v-else>NO</span>
        </template>
        <template v-slot:cell-qualifica_assegnata="{ row: data }">
          <span class="text-success" v-if="data.qualifica_assegnata">SI</span>
          <span class="text-danger" v-else>NO</span>
        </template>
        <template v-slot:cell-tipo_corso="{ row: data }"
          ><div class="tab-long">
            {{ data.tipo_corso }}
          </div></template
        >
        <template v-slot:cell-denominazione="{ row: data }"
          ><div class="tab-long">{{ data.denominazione }}</div></template
        >

        <template v-slot:cell-luogo="{ row: data }"
          ><div class="tab-long">
            {{ data.citta }} ({{ data.provincia }})
          </div></template
        >
        <template v-slot:cell-qualifica_da_attribuire="{ row: data }"
          ><div class="tab-long">
            {{ data.qualifica_da_attribuire }}
          </div></template
        >
        <template v-slot:cell-options="{ row: data }">
          {{ data.options }}
          <div class="btn-group">
            <i
              class="bi bi-card-heading text-dark fs-4"
              @click.prevent="generaAttestato(data)"
            ></i>
            <!-- <button
              v-if="!readOnly"
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a href="#" @click.prevent="generaAttestato(data)">
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-card-heading text-dark fs-4"></i>
                      <span class="text-dark ps-1 fs-7">
                        Genera attestato partecipazione
                      </span>
                    </div>
                  </div>
                </li></a
              > -->
            <!-- <a
                v-if="data.idoneo"
                href="#"
                @click.prevent="generaAttestatoQualifica(data)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-card-heading text-dark fs-4"></i>
                      <span class="text-dark ps-1 fs-7">
                        Genera attestato qualifica
                      </span>
                    </div>
                  </div>
                </li></a
              > -->
            <!-- </ul> -->
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed, watch, getCurrentInstance } from "vue";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

import services from "@/axios/dbManag";
import { useRoute } from "vue-router";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  name: "TableCorsi",
  components: { Datatable, Loading },
  emits: ["getCorsiList", "resetFilters"],
  props: {},

  setup(props, { emit }) {
    const store = useStore();
    const onlyView = ref(false);

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const setFetchRowsCorsi = (e) => {
      store.commit("setFetchRowsCorsiAttestati", e);
      emit("getCorsiList");
    };
    const setCurrentPageCorsi = (page) => {
      store.commit("setCurrentPageCorsiAttestati", page);
      emit("getCorsiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnCorsiAttestati", columnName);
      store.commit("setSortOrderCorsiAttestati", order);
      emit("getCorsiList");
    };

    const refreshList = () => {
      emit("getCorsiList");
    };

    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Stagione",
        key: "stagione",
        sortable: false,
      },
      {
        name: "Nominativo",
        key: "nominativo_completo",
        sortable: true,
      },
      {
        name: "Attestato Generato",
        key: "attestato_generato",
        sortable: false,
      },
      {
        name: "Attestato scaricato",
        key: "scaricato",
        sortable: false,
      },
      {
        name: "Qualifica Assegnata",
        key: "qualifica_assegnata",
        sortable: false,
      },
      {
        name: "Idoneo",
        key: "idoneo",
        sortable: false,
      },
      {
        name: "Qualifica da attribuire",
        key: "qualifica_da_attribuire",
        sortable: false,
      },
      {
        name: "Tipologia",
        key: "tipo_corso",
        sortable: false,
      },
      {
        name: "Denominazione",
        key: "denominazione",
        sortable: false,
      },
      {
        name: "Luogo",
        key: "luogo",
        sortable: false,
      },

      {
        key: "options",
        sortable: false,
      },
    ]);

    const isLoaded = ref(false);
    const route = useRoute();
    const id_corso = ref(route.params.id);

    const isLoading = ref(false);

    const generazioneAttestatoFrequentazioneAPI = async (payload) => {
      isLoading.value = true;
      await services.dbManag
        .post("/snm/corsi/attestati/download-disponibile/add", payload)
        .then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            alertSuccess("Attestati generati con successo");
            emit("getCorsiList");
          }
          return res.data.result ? res.data.result : res.data;
        })
        .catch((e) => {
          isLoading.value = false;

          alertFailed(
            e.response.data.message
              ? e.response.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        });
    };

    const corsi_attestati_list = computed(() =>
      store.getters.getStateFromName("resultscorsi_attestati_list")
    );

    const generaAttestatoPartecipazioneSelezionati = async () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Continuando verranno generati gli attestati di partecipazione per i partecipanti selezionati",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Continua",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        } else {
          let arrayIdPersone = "";
          corsi_attestati_list.value.forEach((element) => {
            if (element.selected) {
              arrayIdPersone = arrayIdPersone + `${element.id_persona},`;
            }
          });
          await generazioneAttestatoFrequentazioneAPI({
            id_corso: id_corso.value,
            id_arr_persone: arrayIdPersone,
          });
        }
      });
    };

    const generaAttestato = async (data) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Continuando verrà generato l'attestato di partecipazione per la persona selezionata",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Continua",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        } else {
          await generazioneAttestatoFrequentazioneAPI({
            id_corso: id_corso.value,
            id_persona: data.id_persona,
          });
        }
      });
    };
    const generaAttestatoPartecipazioneTutti = async () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Continuando verrà generato l'attestato di partecipazione a tutti i partecipanti del corso",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Continua",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        } else {
          await generazioneAttestatoFrequentazioneAPI({
            id_corso: id_corso.value,
          });
        }
      });
    };

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const generaAttestatoQualificaAPI = async (payload) => {
      isLoading.value = true;
      await services.dbManag
        .post(
          globalApi.ALBI_PERSONE_QUALIFICHE_DOWNLOAD_DISPONIBILE_ADD,
          payload
        )
        .then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            alertSuccess("Attestati generati con successo");
            emit("getCorsiList");
          }
          return res.data.result ? res.data.result : res.data;
        })
        .catch((e) => {
          isLoading.value = false;

          alertFailed(
            e.response.data.message
              ? e.response.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        });
    };

    const generaAttestatoQualifica = async (data) => {
      await generaAttestatoQualificaAPI({
        id_persona: data.id_persona,
        id_qualifica: data.id_qualifica_da_attribuire,
      });
    };

    const checkAll = ref(false);

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedcorsi_attestati_list")
    );

    watch(loaded, () => {
      checkAll.value = false;
    });

    return {
      generaAttestatoQualifica,
      generaAttestatoPartecipazioneSelezionati,
      generaAttestatoPartecipazioneTutti,
      checkAll,
      generaAttestato,
      getFormatDate,
      corsi_attestati_list,
      loaded,
      record: computed(() =>
        store.getters.getStateFromName("recordcorsi_attestati_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuscorsi_attestati_list")
      ),

      currentPage: computed(() => store.getters.currentPageCorsiAttestati),
      rowsToSkip: computed(() => store.getters.rowsToSkipCorsiAttestati),
      fetchRows: computed(() => store.getters.fetchRowsCorsiAttestati),
      sortColumn: computed(() => store.getters.sortColumnCorsiAttestati),
      sortOrder: computed(() => store.getters.sortOrderCorsiAttestati),
      setFetchRowsCorsi,
      setCurrentPageCorsi,
      setSortOrderColumn,
      tableHeader,
      isLoaded,
      refreshList,
      id_corso,
      isLoading,
      onlyView,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>
