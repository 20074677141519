<template>
  <div>
    <FiltriGeneraAttestati
      @getCorsiList="searchCorsiList"
      @resetFilters="resetFilters"
    />
    <TableGeneraAttestati
      @getCorsiList="getCorsiList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import FiltriGeneraAttestati from "@/components/components-fit/SNM/gestione-corsi/FiltriGeneraAttestati.vue";
import TableGeneraAttestati from "@/components/components-fit/SNM/gestione-corsi/TableGeneraAttestati.vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "corsi",
  components: { FiltriGeneraAttestati, TableGeneraAttestati },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Lista Corsi", []);
    });

    const store = useStore();

    const route = useRoute();
    const id_corso = route.params.id;

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipCorsiAttestati);
    const fetchRows = computed(() => store.getters.fetchRowsCorsiAttestati);
    const sortColumn = computed(() => store.getters.sortColumnCorsiAttestati);
    const sortOrder = computed(() => store.getters.sortOrderCorsiAttestati);

    const tipo = computed(() => store.getters.id_tipo_corsiCorsiAttestati);

    const cognome = computed(() => store.getters.cognomeCorsiAttestati);
    const nome = computed(() => store.getters.nomeCorsiAttestati);
    const idoneoCorsiAttestati = computed(
      () => store.getters.idoneoCorsiAttestati
    );

    const id_inviato = computed(() => store.getters.id_inviatoCorsiAttestati);
    const id_scaricato = computed(
      () => store.getters.id_scaricatoCorsiAttestati
    );

    const getCorsiList = () => {
      store.dispatch("setStoreListData", {
        // keys: {
        //   stagione: stagione.value,
        //   id_tipo_corso: id_tipo_corso.value,
        //   id_sottotipo_corso: id_sottotipo_corso.value,
        //   search: trimInput(search.value),
        //   rowstoskip: rowsToSkip.value,
        //   fetchrows: fetchRows.value,
        //   sortcolumn: sortColumn.value,
        //   sortorder: sortOrder.value,
        // },
        keys: {
          id_corso: id_corso,
          cognome: cognome.value,
          nome: nome.value,
          idoneo: idoneoCorsiAttestati.value,
          tipo: tipo.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
          inviato: id_inviato.value,
          scaricato: id_scaricato.value,
        },
        apiLink: globalApi.SNM_CORSI_ATTESTATI_GENERA_LIST,
        itemName: "corsi_attestati_list",
      });
    };

    const resetFilters = () => {
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      store.commit("resetFiltersCorsiAttestati");
      getCorsiList();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedcorsi_attestati_list")
    );
    const searchCorsiList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipCorsiAttestati");
      getCorsiList();
    };

    getCorsiList();

    return {
      getCorsiList,
      resetFilters,
      searchCorsiList,
    };
  },
});
</script>
