<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getCorsiList')">
        <div class="row">
          <div class="col-sm-6 filter">
            <input
              placeholder="Cognome"
              name="cognome"
              type="text"
              class="form-control"
              autocomplete="off"
              :value="cognome"
              @input="setCognome($event.target.value)"
            />
          </div>
          <div class="col-sm-6 filter">
            <input
              placeholder="Nome"
              name="nome"
              type="text"
              class="form-control"
              autocomplete="off"
              :value="nome"
              @input="setNome($event.target.value)"
            />
          </div>
        </div>
        <div class="row pt-4 pb-3">
          <div class="col-sm-4 filter">
            <SelectInput
              :options="idoneo_lookup"
              name="tipo_corsi"
              placeholder="Idoneo"
              :value="idoneo"
              @changeSelect="setIdoneo($event)"
            />
          </div>
          <div class="col-sm-4 filter">
            <SelectInput
              :options="trueFalseLookup"
              name="Inviato"
              placeholder="Inviato"
              :value="id_inviato"
              @changeSelect="setIdInviato($event)"
            />
          </div>
          <div class="col-sm-4 filter">
            <SelectInput
              :options="trueFalseLookup"
              name="Scaricato"
              placeholder="Scaricato"
              :value="id_scaricato"
              @changeSelect="setIdScaricato($event)"
            />
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getCorsiList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="
              $emit('resetFilters');
              resetCorsiSpec();
              specializzCorsi = null;
            "
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import "vue3-date-time-picker/dist/main.css";

export default defineComponent({
  name: "filtri-corsi",
  emits: ["resetFilters", "getCorsiList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  components: {
    SelectInput,
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const snm_corsi_tipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_tipo")
    );

    const snm_corsi_sottotipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_sottotipo")
    );

    const tipologiaCorsi = computed(() => store.getters.tipologiaCorsi);
    const specializzCorsi = computed(() => store.getters.specializzCorsi);
    const denominazioneCorsi = computed(() => store.getters.denominazioneCorsi);

    const keys = ref("sct");
    if (!snm_corsi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const setDenominazioneCorsi = (e) => {
      const string = e.target.value;
      store.commit("setDenominazioneCorsi", string);
    };

    const setTipologiaCorsi = (event) => {
      store.commit("setTipologiaCorsi", event);
    };

    const setSpecializzCorsi = (event) => {
      store.commit("setSpecializzCorsi", event);
    };

    const getSpecializzCorsi = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_Tipo_Corso: id },
        apiLink: globalApi.LOOKUP_SPECIALIZZAZIONE_CORSI,
      });
    };

    const resetCorsiSpec = () => {
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      specializzCorsi.value = null;
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getCorsiList");
    });

    const getPersone = (string) => {
      store.commit("setNomePersonaCorsiAttestati", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const setIdPersonaCorsiAttestati = ({ value, name }) => {
      store.commit("setIdPersonaCorsiAttestati", value);
      store.commit("setNomePersonaCorsiAttestati", name);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };
    const inputFocus = ref("");

    const setIdoneo = (e) => {
      store.commit("setIdoneoCorsiAttestati", e);
    };
    const setid_tipo_corsi = (event) => {
      store.commit("setId_tipo_corsiCorsiAttestati", event);
    };

    const setIdInviato = (event) => {
      store.commit("setIdInviatoCorsiAttestati", event);
    };
    const setIdScaricato = (event) => {
      store.commit("setIdScaricatoCorsiAttestati", event);
    };
    const setNome = (event) => {
      store.commit("setNomeCorsiAttestati", event);
    };
    const setCognome = (event) => {
      store.commit("setCognomeCorsiAttestati", event);
    };

    return {
      setNome,
      setCognome,
      setIdInviato,
      setIdScaricato,
      setid_tipo_corsi,
      setIdoneo,
      id_tipo_corsi: computed(() => store.getters.id_tipo_corsiCorsiAttestati),
      idoneo: computed(() => store.getters.idoneoCorsiAttestati),
      cognome: computed(() => store.getters.cognomeCorsiAttestati),
      nome: computed(() => store.getters.nomeCorsiAttestati),
      nomePersonaCorsiAttestati: computed(
        () => store.getters.nomePersonaCorsiAttestati
      ),
      id_inviato: computed(() => store.getters.id_inviatoCorsiAttestati),
      id_scaricato: computed(() => store.getters.id_scaricatoCorsiAttestati),
      inputFocus,
      setIdPersonaCorsiAttestati,
      resetTesserati,
      getPersone,
      tesserati: computed(() => store.getters.personeSocieta),
      resetCorsiSpec,
      stagione: computed(() => store.getters.stagioneSelected),
      snm_corsi_tipo,
      getSpecializzCorsi,
      snm_corsi_sottotipo,
      setDenominazioneCorsi,
      denominazioneCorsi,
      setSpecializzCorsi,
      specializzCorsi,
      setTipologiaCorsi,
      tipologiaCorsi,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcorsi_attestati_list")
      ),
      tipo_corsi: [
        {
          id: "Q",
          label: "Qualifiche",
        },
        {
          id: "A",
          label: "Attestati",
        },
      ],
      idoneo_lookup: [
        {
          id: true,
          label: "SI",
        },
        {
          id: false,
          label: "No",
        },
      ],
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
    };
  },
});
</script>

<style></style>
